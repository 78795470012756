import React from "react"
import SingleImage from "../SingleImage"
import DoubleImage from "../DoubleImage"
import TripleImage from "../TripleImage"
import QuadImage from "../QuadImage"


const artImg1 = "/img/art_01.jpg"
const artImg2 = "/img/art_02.jpg"
const artImg3 = "/img/art_03.jpg"
const artImg4 = "/img/art_04.jpg"
const artImg5 = "/img/art_05.jpg"
const artImg6 = "/img/art_06.jpg"
const artImg7 = "/img/art_07.jpg"
const artImg8 = "/img/art_08.jpg"
const artImg9 = "/img/art_09.jpg"
const artImg10 = "/img/art_10.jpg"
const artImg11 = "/img/art_11.jpg"
const artImg12 = "/img/art_12.jpg"
const artImg13 = "/img/art_13.jpg"
const artImg14 = "/img/art_14.jpg"
const artImg15 = "/img/art_15.jpg"
const artImg16 = "/img/art_16.jpg"
const artImg17 = "/img/art_17.jpg"
const artImg18 = "/img/art_18.jpg"
// const artImg19 = "/img/art_19.jpg"
// const artImg20 = "/img/art_20.jpg"
const artImg21 = "/img/art_21.jpg"
const artImg22 = "/img/art_22.jpg"
const artImg23 = "/img/art_23.jpg"
const artImg24 = "/img/art_24.jpg"
const artImg25 = "/img/art_25.jpg"
const artImg26 = "/img/art_26.jpg"
const artImg27 = "/img/art_27.jpg"
const artImg28 = "/img/art_28.jpg"
const artImg29 = "/img/art_29.jpg"
const artImg30 = "/img/art_30.jpg"
const artImg31 = "/img/art_31.jpg"
const artImg32 = "/img/art_32.jpg"
const artImg33 = "/img/art_33.jpg"
const artImg34 = "/img/art_34.jpg"
const artImg35 = "/img/art_35.jpg"
const artImg36 = "/img/art_36.jpg"
const artImg37 = "/img/art_37.jpg"
const artImg38 = "/img/art_38.jpg"
const artImg39 = "/img/art_39.jpg"
const artImg40 = "/img/art_40.jpg"
const artImg41 = "/img/art_41.jpg"
const artImg42 = "/img/art_42.jpg"
const artImg43 = "/img/art_43.jpg"
const artImg44 = "/img/art_44.jpg"
const artImg45 = "/img/art_45.jpg"
const artImg46 = "/img/art_46.jpg"
const artImg47 = "/img/art_47.jpg"
const artImg48 = "/img/art_48.jpg"
const artImg49 = "/img/art_49.jpg"
const artImg50 = "/img/art_50.jpg"
const artImg51 = "/img/art_51.jpg"
const artImg52 = "/img/art_52.jpg"
const artImg53 = "/img/art_53.jpg"
const artImg54 = "/img/art_54.jpg"
const artImg55 = "/img/art_55.jpg"
const artImg56 = "/img/art_56.jpg"
const artImg57 = "/img/art_57.jpg"
const artImg58 = "/img/art_58.jpg"
const artImg59 = "/img/art_59.jpg"
const artImg60 = "/img/art_60.jpg"
const artImg61 = "/img/art_61.jpg"
const artImg62 = "/img/art_62.jpg"
const artImg63 = "/img/art_63.jpg"
const artImg64 = "/img/art_64.jpg"
const artImg65 = "/img/art_65.jpg"
const artImg66 = "/img/art_66.jpg"
//const artImg67 = "/img/art_67.jpg"

const artImgB1 = "/img/artB_01.jpg"
const artImgB2 = "/img/artB_02.jpg"
const artImgB3 = "/img/artB_03.jpg"
const artImgB4 = "/img/artB_04.jpg"
const artImgB5 = "/img/artB_05.jpg"
const artImgB6 = "/img/artB_06.jpg"
const artImgB7 = "/img/artB_07.jpg"
const artImgB8 = "/img/artB_08.jpg"
const artImgB9 = "/img/artB_09.jpg"
const artImgB10 = "/img/artB_10.jpg"
const artImgB11 = "/img/artB_11.jpg"
const artImgB12 = "/img/artB_12.jpg"
const artImgB13 = "/img/artB_13.jpg"
const artImgB14 = "/img/artB_14.jpg"
const artImgB15 = "/img/artB_15.jpg"
const artImgB16 = "/img/artB_16.jpg"
const artImgB17 = "/img/artB_17.jpg"
const artImgB18 = "/img/artB_18.jpg"
const artImgB19 = "/img/artB_19.jpg"






class Art extends React.Component {
    constructor(){
        super()
        this.state = {
            loadMore: false, 
            loadMoreB: false,          
        }
    }

render() {  
    return( 
        <div className= { this.props.darkMode ?  "page pageDark" : "page" }>
            <div className= { this.props.darkMode ? "myArtPage myArtPageDark" :  "myArtPage" }> 

                <div>  

                    <SingleImage 
                        imgURL={artImgB2} 
                    />  
                    <SingleImage 
                        imgURL={artImgB3} 
                    />  
                    <SingleImage 
                        imgURL={artImgB1} 
                    />  
                      <DoubleImage  
                        imgURLA={artImgB4} 
                        imgURLB={artImgB5} 
                    />   
                    <SingleImage 
                        imgURL={artImgB6} 
                    /> 
                      <SingleImage 
                        imgURL={artImgB7} 
                    /> 
                    <DoubleImage  
                        imgURLA={artImgB8} 
                        imgURLB={artImgB9} 
                    />  
                      <SingleImage 
                        imgURL={artImgB10} 
                    /> 
                    <DoubleImage  
                        imgURLA={artImgB11} 
                        imgURLB={artImgB12} 
                    />  
                    <SingleImage 
                        imgURL={artImgB13} 
                    /> 
                    <DoubleImage  
                        imgURLA={artImgB14} 
                        imgURLB={artImgB15} 
                    />  
                     <SingleImage 
                        imgURL={artImgB16} 
                    /> 
                     <SingleImage 
                        imgURL={artImgB17} 
                    /> 
                     <SingleImage 
                        imgURL={artImgB18} 
                    /> 
                     <SingleImage 
                        imgURL={artImgB19} 
                    /> 

                    

                    {/* <div className ="imgGridCenter"> 
                        {!this.state.loadMore ?  
                            <div className="loadMoreBG" onClick={ () => this.setState({loadMore: true}) }>
                               <div className = "loadMoreText"> [LOAD MORE] </div>
                            </div>
                        :
                        <div>                  
                       ---loaded content...---
                        </div>
                    }
                    </div>                */}

<TripleImage 
                            imgURLA={artImg1} 
                            imgURLB={artImg2} 
                            imgURLC={artImg3}  
                        />                    
                        <DoubleImage  
                            imgURLA={artImg4} 
                            imgURLB={artImg5} 
                        />                                      
                        <QuadImage  
                            imgURLA={artImg6} 
                            imgURLB={artImg7} 
                            imgURLC={artImg8} 
                            imgURLD={artImg9} 
                        />
                        <SingleImage 
                            imgURL={artImg10} 
                        />  
                        <DoubleImage  
                            imgURLA={artImg11} 
                            imgURLB={artImg12} 
                        />                    
                        <QuadImage  
                            imgURLA={artImg13} 
                            imgURLB={artImg14} 
                            imgURLC={artImg15} 
                            imgURLD={artImg16} 
                        />
                        
                        <SingleImage
                            imgURL={artImg17} 
                        />

                        <SingleImage  
                            imgURL={artImg18} 
                        /> 

                        <SingleImage  
                            imgURL={artImg21} 
                        /> 

                       {/*
                        <TripleImage 
                            imgURLA={artImg19} 
                            imgURLB={artImg20} 
                            imgURLC={artImg21}  
                        />       
                       */}

                        <QuadImage  
                            imgURLA={artImg22} 
                            imgURLB={artImg23} 
                            imgURLC={artImg24} 
                            imgURLD={artImg25} 
                        />
                        <DoubleImage  
                            imgURLA={artImg26} 
                            imgURLB={artImg27} 
                        />    
                        <TripleImage 
                            imgURLA={artImg28} 
                            imgURLB={artImg29} 
                            imgURLC={artImg30}  
                        /> 
                                
                        <SingleImage 
                            imgURL={artImg31} 
                        />  
                        <TripleImage 
                            imgURLA={artImg32} 
                            imgURLB={artImg33} 
                            imgURLC={artImg34}  
                        /> 
                        <SingleImage 
                            imgURL={artImg35} 
                        />  
                        <DoubleImage  
                            imgURLA={artImg36} 
                            imgURLB={artImg37} 
                        />  
                          
                        <TripleImage 
                            imgURLA={artImg38} 
                            imgURLB={artImg39} 
                            imgURLC={artImg40}  
                        /> 
                        
                        <SingleImage  
                            imgURL={artImg41} 
                            /> 
                        <TripleImage  
                            imgURLA={artImg42} 
                            imgURLB={artImg43} 
                            imgURLC={artImg44} 
                        /> 
                        <SingleImage  
                            imgURL={artImg45} 
                        /> 
                        <SingleImage  
                            imgURL={artImg46} 
                        /> 
                        <TripleImage  
                            imgURLA={artImg47} 
                            imgURLB={artImg48} 
                            imgURLC={artImg49} 
                        /> 
                        <DoubleImage  
                            imgURLA={artImg50} 
                            imgURLB={artImg51} 
                        />    
                        <SingleImage  
                            imgURL={artImg52} 
                        /> 
                        <QuadImage  
                            imgURLA={artImg53} 
                            imgURLB={artImg54} 
                            imgURLC={artImg55} 
                            imgURLD={artImg56} 
                        />
                        <TripleImage 
                            imgURLA={artImg57} 
                            imgURLB={artImg58} 
                            imgURLC={artImg59}  
                        />   
                        <SingleImage  
                            imgURL={artImg60} 
                        />    
                        <SingleImage  
                            imgURL={artImg61} 
                        />  
                        <TripleImage 
                            imgURLA={artImg62} 
                            imgURLB={artImg63} 
                            imgURLC={artImg64}  
                        />   
                        <SingleImage  
                            imgURL={artImg65} 
                        />    
                        <SingleImage  
                            imgURL={artImg66} 
                        /> 
                </div>
            </div>
        </div>
    )}
}

export default Art