import React from "react"


const doorLogo = window.location.origin + "/img/secretdoor_logo-text.svg"

const footerCopyright = "© Finlay Paterson 2024"
const secretDoorInfoPageURL = "https://www.finlaypaterson.ca/secretdoor";
const secretDoorDownloadPageURL = "https://www.finlaypaterson.ca/secretdoordownloads";


class DownloadUnavailable extends React.Component {
    constructor(){
        super()
        this.state = {
            enableVideoA: false,           
            // enableVideoB: false,           
            // enableVideoC: false,           
        }
      }

    render() {
        return(
        <div className= "secretdoor_bg">
        <div className="secretDoor_spaceBG staticPage">
            <div id="!"></div>
            <div className = "secretdoor_pageDivDownloads">

     <br/>     <br/>     <br/>  
   <br/>  <br/>  <br/>  
   <a href={secretDoorInfoPageURL}>
                    <img alt="Secret Door" src={doorLogo} width="300" height="150"/>
   </a>
                    <div className = "secretdoordownloads_textBoxHeader secretdoor_textBoxTitleCentered">
                        Public Beta
                    </div>
                    <br/>  


<div className = "secretdoordownloads_textBoxTitle secretdoor_textBoxTitleCentered">
                   
                </div>
                <div className="secretdoor_downloadPageSkinnyCard">
                    <a href={secretDoorDownloadPageURL}>
                        <div className = "secretdoor_textBox">
                            <div className="secretdoor_downloadPageSkinnyText">
                            <br/>
                            This download is temporarily unavailable.
                            </div>
                            <br/>

                        </div>
                    </a>
                </div>


                <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>   
                   
                <div className = "secretdoor_footerTextBoxName">
                        <div className = "whiteText">
                            {footerCopyright}

                        </div>
                </div>        
                    <br/><br/>
                    </div>
                </div>                   
            </div>
            )}
        }
export default DownloadUnavailable


