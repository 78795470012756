import React from "react"


const doorLogo = window.location.origin + "/img/secretdoor_logo-text.svg"
const appleLogo = window.location.origin + "/img/apple.png"
const iosLogo = window.location.origin + "/img/ios.png"
const androidLogo = window.location.origin + "/img/android.png"
const windowsLogo = window.location.origin + "/img/windows.png"
const linuxLogo = window.location.origin + "/img/linux.png"

//LIVE DOWNLOAD LINKS
// const GoogleDriveAndroidDownload =  "https://drive.google.com/file/d/1uKrppTr0UEebO-bs_pQjjjHdHJlvYRYE";
// const GoogleDriveMacDownload = "https://drive.google.com/file/d/1NDimfr3592he7adeOFZ_cm3_LxRcxhR7";
// const GoogleDriveWindowsDownload = "https://drive.google.com/file/d/1hCZVNTgt_V37aQxd5wTYT-rFbJ4opnW3";
// const GoogleDriveLinuxDownload = "https://drive.google.com/file/d/1vvYkVaBg2KaUyIbs19wev5HrW0FfNEq0";
// const TestFlightPublicLink = "https://testflight.apple.com/join/UGbkv9h5";//public testflight link

//DISABLED DOWNLOAD LINKS
const GoogleDriveAndroidDownload =  "https://www.finlaypaterson.ca/downloadunavailable";
const GoogleDriveMacDownload = "https://www.finlaypaterson.ca/downloadunavailable";
const GoogleDriveWindowsDownload = "https://www.finlaypaterson.ca/downloadunavailable";
const GoogleDriveLinuxDownload = "https://www.finlaypaterson.ca/downloadunavailable";
const TestFlightPublicLink = "https://www.finlaypaterson.ca/downloadunavailable";





const footerCopyright = "© Finlay Paterson 2024"
const secretDoorInfoPageURL = "https://www.finlaypaterson.ca/secretdoor#!";


class SecretDoorDownloadPage extends React.Component {
    constructor(){
        super()
        this.state = {
            enableVideoA: false,           
            // enableVideoB: false,           
            // enableVideoC: false,           
        }
      }

    render() {
        return(
        <div className= "secretdoor_bg">
        <div className="secretDoor_spaceBG" >
            <div id="!"></div>
            <div className = "secretdoor_pageDivDownloads">

    {/* using dynamic video loader, when we click on the DIV then load the youtube embeded video... */}
                               
                               

    {/* old method video loader... */}

                        {/* <a href="https://www.youtube.com/watch?v=FiZBmjXmP38" target="_blank" rel="noopener noreferrer"> 
                            <div className="secretdoor_infoCardLink">                
                                <img 
                                    className="videoPreviewImg_secretDoor"
                                    src={trailerVideoImg} 
                                    alt=""
                                    //  onClick={ () => props.setState({enableVideo: true}) }
                                /> 
                                <div className="secretdoor_textBoxCenter">Click here to watch the Teaser Trailer</div>
                            </div>
                        </a> */}
   <br/>     <br/>     <br/>  
   <br/>  <br/>  <br/>  
   <a href={secretDoorInfoPageURL}>
                    <img alt="Secret Door" src={doorLogo} width="300" height="150"/>
   </a>
                    <div className = "secretdoordownloads_textBoxHeader secretdoor_textBoxTitleCentered">
                        Public Beta
                    </div>
                    <br/>  




                <div className = "secretdoordownloads_textBoxTitle secretdoor_textBoxTitleCentered">
                    Play on Phones and Tablets:
                </div>

                <a href={TestFlightPublicLink}>
                    <div className="secretdoor_downloadPageCard">
                        <img alt="" className="downloadIconGraphic" src={iosLogo}/>
                        <div className = "secretdoor_textBox">

                        <div className="secretdoor_downloadPageText">
                                    
                                Download for iOS                       
                                    </div>
                        </div>
                    </div>
                </a>

                <a href={GoogleDriveAndroidDownload}>
                    <div className="secretdoor_downloadPageCard">
                            <img alt="" className="downloadIconGraphic" src={androidLogo} />
                            <div className = "secretdoor_textBox">
                            <div className="secretdoor_downloadPageText">                                
                                Download for Android   
                    
                            </div>
                        </div>
                    </div>
                </a>

                <br/>  
                <br/>  
                <br/>  
                <br/>  
                <div className = "secretdoordownloads_textBoxTitle secretdoor_textBoxTitleCentered">
                    Play on Desktop:
                </div>
                <div className="secretdoor_downloadPageCard">
                    <a href={GoogleDriveMacDownload}>
                        <img alt="" className="downloadIconGraphic" src={appleLogo} />
                        <div className = "secretdoor_textBox">
                            <div className="secretdoor_downloadPageText">
                                Download for MacOS Desktop                                                      
                            </div>
                        </div>
                    </a>
                </div>

                <div className="secretdoor_downloadPageCard">
                    <a href={GoogleDriveWindowsDownload}>
                    <img alt="" className="downloadIconGraphic" src={windowsLogo} />
                        <div className = "secretdoor_textBox">
                            <div className="secretdoor_downloadPageText">
                                Download for Windows Desktop                                
                            </div>
                        </div>
                    </a>
                </div>
                <div className="secretdoor_downloadPageCard">
                    <a href={GoogleDriveLinuxDownload}>
                        <img alt="" className="downloadIconGraphic" src={linuxLogo} />
                        <div className = "secretdoor_textBox">
                            <div className="secretdoor_downloadPageText">
                                Download for Linux Desktop
                            </div>
                        </div>
                    </a>
                </div>

                
                <br/>  
                <br/>  
                <br/>  
                <br/>  
                <div className = "secretdoordownloads_textBoxTitle secretdoor_textBoxTitleCentered">
                   To learn more about the game:
                </div>
                <div className="secretdoor_downloadPageSkinnyCard">
                    <a href={secretDoorInfoPageURL}>
                        <div className = "secretdoor_textBox">
                            <div className="secretdoor_downloadPageSkinnyText">
                                Visit the Secret Door Beta webpage
                            </div>
                        </div>
                    </a>
                </div>


                <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>      <br/>   
                   
                <div className = "secretdoor_footerTextBoxName">
                    <div className = "whiteText">
                        {footerCopyright}
                    </div>
                </div>        
                    <br/><br/>
                    </div>
                </div>                   
            </div>
            )}
        }
export default SecretDoorDownloadPage


