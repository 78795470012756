import React from "react"
import TripleImage from "./TripleImage"
// import DoubleImage from "./DoubleImage"


// const doorIcon = window.location.origin + "/img/secretdoor_icon.svg"
const doorLogo = window.location.origin + "/img/secretdoor_logo.svg"
const gameIcon = window.location.origin + "/img/gameIcon.png"
const landscapeVideoImg = "/img/LandscapeVideoImage.png"
const portraitVideoImg = "/img/PortraitVideoImage.png"
// const trailerVideoImg = "/img/TrailerVideoImage.jpg"
// const trailerVideoImgB = "/img/TrailerVideoImageB.jpg"
// const trailerVideoImgC = "/img/TrailerVideoImageC.jpg"
// const trailerVideoImgD = "/img/TrailerVideoImageD.jpg"

// const testFlightBetaURL = ""
// const testFlightBetaURL = "PUT TESTFLIGHT URL HERE..."

const Screenshot_01 = "/img/Screenshot_01.jpg"
const Screenshot_02 = "/img/Screenshot_02.jpg"
const Screenshot_03 = "/img/Screenshot_03.jpg"
const Screenshot_04 = "/img/Screenshot_04.jpg"
const Screenshot_05 = "/img/Screenshot_05.jpg"
const Screenshot_06 = "/img/Screenshot_06.jpg"
const Screenshot_07 = "/img/Screenshot_07.jpg"
const Screenshot_08 = "/img/Screenshot_08.jpg"
const Screenshot_09 = "/img/Screenshot_09.jpg"
const Screenshot_10 = "/img/Screenshot_10.jpg"
const Screenshot_11 = "/img/Screenshot_11.jpg"
const Screenshot_12 = "/img/Screenshot_12.jpg"
const Screenshot_13 = "/img/Screenshot_13.jpg"
const Screenshot_14 = "/img/Screenshot_14.jpg"
const Screenshot_15 = "/img/Screenshot_15.jpg"
// const Screenshot_16 = "/img/Screenshot_16.jpg"
const Screenshot_17 = "/img/Screenshot_17.jpg"
// const Screenshot_18 = "/img/Screenshot_18.jpg"
const Screenshot_19 = "/img/Screenshot_19.jpg"
// const Screenshot_20 = "/img/Screenshot_20.jpg"
const Screenshot_21 = "/img/Screenshot_21.jpg"




// const portraitPromoD = "/img/portraitScreenshot_A.jpg"

// const landscapePromoA = "/img/lanscapeScreenshot_B.jpg"
// const landscapePromoB = "/img/lanscapeScreenshot_A.jpg"
// const landscapePromoC = "/img/lanscapeScreenshot_C.jpg"
// const landscapePromoD = "/img/lanscapeScreenshot_D.jpg"

const footerCopyright = "© Finlay Paterson 2024"

const iosLinkImg = "/img/appStoreIcon.png"
const androidImg = "/img/playStoreIcon.png"
// const playstationImg = "/img/playstationIcon.png"
// const xboxImg = "/img/xboxIcon.png"
// const switchImg = "/img/switchIcon.png"
// const steamImg = "/img/steamIcon.png"
// const itchImg = "/img/itchIcon_white"

const paragraphA = "is a 3rd Person Open-World Narrative Adventure Game."
const paragraphB = "This non-violent game is focused on player choice and decision making, with a world full of characters to talk to and unique places to explore.";

// const paragraphC = "Players can expect around 5 hours of narrative driven open world exploration to complete the main storyline, as well as multiple side-stories, events, and mini-games.";
// const paragraphD =  "The game supports touchscreen and game controller inputs, and runs on iPhone, Android, Mac, and PC.";
// const paragraphE =  "Story Synopsis:";
// const paragraphF =  "Secret Door is set on the Lost Islands, which were discovered decades prior, and contained the ruins of an ancient civilization.";
// const paragraphG =  "Now the home of multiple cities and towns, the islands' ruins have become a tourist destination known across the globe.";
// const paragraphH =  "In Secret Door you play as a new visitor to the islands, who discovers a lost artifact, uncovering an ancient mystery.";

// const paragraphC =  "The main storyline leads players through a fantasy land rich with open-world content.  Full of side-stories, and mini-games, Secret Door will keep you busy with roughly 5-10 hours of content if you want to see everything.";
const paragraphD =  "Secret Door supports touchscreen and game controller inputs, as well as mouse and keyboard on supported platforms.  The game currently runs on iOS, Android, Mac, PC, and Linux.";    
const paragraphF =  "Story Synopsis:";
const paragraphG =  "Some 50 years ago, a mysterious disaster shook the planet.  At that time, a new archipelago of islands appeared in the ocean.  The Lost Islands, which contained the ruins of ancient structures from a forgotten past."; 
const paragraphH =  "In Secret Door, players take on the roll of an aspiring archeologist who makes the discovery of a lifetime while visiting the famous Lost Islands.";


class GameWebpage extends React.Component {
    constructor(){
        super()
        this.state = {
            enableVideoA: false,           
            // enableVideoB: false,           
            // enableVideoC: false,           
        }
      }

    render() {
        return(
        <div className= "secretdoor_bg">
                <a href="#!">
                    <div className = "secretdoor_header">
                        <div className = "logoGraphics">
                            <div className = "secretdoor_iconbox">
                                <div className = "secretdoor_logobox">
                                    <img alt="Secret Door" src={doorLogo} width="350" height="350"/>
                                </div> 
                                {/* <div className="doorIconGroupNew">
                                    <img alt="" src={doorIcon} width="100" height="100"/>
                                </div> */}
                            </div>
                        </div>
                    </div>           
                    
                    <div className="pageBreakGraphic"></div>
                    <div className="pageBreakGraphicB"></div>
                    <div className="pageBreakGraphicC"></div>
                </a>
        <div className="secretDoor_spaceBG">


            <br/> 
            <br/> 
            <br/>  
            <div id="!"></div>
            <div className = "secretdoor_pageDiv">

    {/* using dynamic video loader, when we click on the DIV then load the youtube embeded video... */}
                                {!this.state.enableVideoA ? 
                                <div onClick={ () => this.setState({enableVideoA: true}) }>
                                    <div className="secretdoor_infoCardLink">                
                                      <div className="videoPreviewImg_secretDoor">
                                        <div className='container'>
                                            <div className='pic' id='pic4' />
                                            <div className='pic' id='pic3' />
                                            <div className='pic' id='pic2' />
                                            <div className='pic' id='pic1' />
                                        </div>
                                    </div>  

                                        {/* <img 
                                            className="videoPreviewImg_secretDoor"
                                            src={trailerVideoImg} 
                                            alt=""
                                            //  onClick={ () => props.setState({enableVideo: true}) }
                                            />                                         */}
                                        <div className="secretdoor_textBoxCenter">Click here to watch the Teaser Trailer</div>
                                    </div>
                                </div>
                                :  
                                <div className="secretdoor_infoCard">  
                                    <div className="sixteenNineAspect">                               
                                        <iframe 
                                            className = "movie"
                                            title="YoutubeVideo"
                                            id="0912"                                
                                            src="https://www.youtube.com/embed/FiZBmjXmP38?playlist=FiZBmjXmP38&hd=1&rel=0&autoplay=1&loop=1&playsinline=1" 
                                            frameBorder="0" 
                                            allow="autoplay; encrypted-media; loop;" 
                                            allowFullScreen

                                            >
                                        </iframe> 
                                    </div>            
                                    <a href="https://www.youtube.com/watch?v=FiZBmjXmP38" target="_blank" rel="noopener noreferrer">                                    
                                        <div className="secretdoor_textBoxCenter" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Click to open trailer in new window</div>
                                    </a>
                                </div>       
                                }


    {/* old method video loader... */}

                        {/* <a href="https://www.youtube.com/watch?v=FiZBmjXmP38" target="_blank" rel="noopener noreferrer"> 
                            <div className="secretdoor_infoCardLink">                
                                <img 
                                    className="videoPreviewImg_secretDoor"
                                    src={trailerVideoImg} 
                                    alt=""
                                    //  onClick={ () => props.setState({enableVideo: true}) }
                                /> 
                                <div className="secretdoor_textBoxCenter">Click here to watch the Teaser Trailer</div>
                            </div>
                        </a> */}


                <div className="secretdoor_infoCardMainText">
                    <div className = "secretdoor_textBox">
                    <div className="imgGridCenter">
                        <img className="appIconImagePortrait" alt="" src={gameIcon} width="100" height="100"/>
                    </div>
                        <div className="secretDoorIndentedParagraphStyle">                     
                            <img className="appIconImage" alt="" src={gameIcon} width="160" height="160"/>
                            <div>
                            <b>Secret Door</b> {paragraphA}
                                <br></br>
                                <br></br>
                                {paragraphB}
                                <br></br>


                            </div>
                        </div>
                        <div>
                            <br></br>
                            {/* 
                            {paragraphC} */}
                            {/* <br></br><br></br> */}
                            {paragraphD} 
                            <br></br><br></br><br></br>
                            <div className="secretdoor_textBoxTitle">{paragraphF}</div>
                            {paragraphG}
                            <br></br><br></br>
                            {paragraphH}      
                        </div>                
                    </div>
                </div>


        
    {/*             
                <br/>            

                <br/> */}

             {/* the preview images will link directly to youtube: */}
             <div className="gameplayVideoGroup" >
                <a href="https://www.youtube.com/watch?v=d8Eu4HruEVE" target="_blank" rel="noopener noreferrer"> 
                    <div className="secretdoor_infoCardPaddedB">

                        <img alt="Secret Door - Portrait Preview" src={landscapeVideoImg} className = "secretdoor_twoImageDiv" width = "100%"/>
                        <div className="secretdoor_textBoxCenter">Watch 1.5 hour gameplay video (landscape)</div>

                    </div>
                </a>
                <a href="https://www.youtube.com/watch?v=fX8A-lUupiU" target="_blank" rel="noopener noreferrer"> 
                    <div className="secretdoor_infoCardPaddedA">                    
                        <img alt="Secret Door - Landscape Preview" src={portraitVideoImg} className = "secretdoor_twoImageDiv" width = "100%"/>
                        <div className="secretdoor_textBoxCenter">Watch 1.5 hour gameplay video (portrait)</div>
                    </div>
                </a>
                </div>

                {/* <br/> */}
                
                <div className="secretdoor_infoCardMainText">
                    <div className="secretdoor_textBoxTitle">
                        <div className="imgGridCenter">
                         Screenshot Gallery: </div> </div>

                     <TripleImage 
                        imgURLA={Screenshot_01} 
                        imgURLB={Screenshot_02} 
                        imgURLC={Screenshot_03}  
                    />   
                       <TripleImage 
                        imgURLA={Screenshot_04} 
                        imgURLB={Screenshot_05} 
                        imgURLC={Screenshot_06}  
                    />   
                       <TripleImage 
                        imgURLA={Screenshot_07} 
                        imgURLB={Screenshot_08} 
                        imgURLC={Screenshot_09}  
                    />   
                       <TripleImage 
                        imgURLA={Screenshot_12} 
                        imgURLB={Screenshot_11} 
                        imgURLC={Screenshot_13}  
                    />   
                       <TripleImage 
                        imgURLA={Screenshot_21} 
                        imgURLB={Screenshot_19} 
                        imgURLC={Screenshot_10}  
                    />    
                       <TripleImage 
                        imgURLA={Screenshot_15} 
                        imgURLB={Screenshot_17} 
                        imgURLC={Screenshot_14}  
                    />                                      
                </div>

                {/* <div className="gameplayVideoGroup" >
                    {!this.state.enableVideoB ? 
                        <div onClick={ () => this.setState({enableVideoB: true}) }>
                            <div className="secretdoor_infoCardPaddedB">
                                <img alt="Secret Door - Portrait Preview" src={landscapeVideoImg} className = "secretdoor_twoImageDiv" width = "100%"/>
                                <div className="secretdoor_textBoxCenter">90 Minute Gameplay Video (landscape)</div>
                            </div>                                   
                        </div>
                        :  
                        <div className="secretdoor_infoCardPaddedB">  
                            <div className="sixteenNineAspect">                               
                                <iframe 
                                    className = "movie"
                                    title="YoutubeVideo"
                                    id="0912"                                
                                    src="https://www.youtube.com/embed/d8Eu4HruEVE" 
                                    frameBorder="0" 
                                    allow="autoplay; encrypted-media;" 
                                    allowFullScreen
                                    >
                                </iframe> 
                            </div>            
                            <a href="https://www.youtube.com/watch?v=d8Eu4HruEVE" target="_blank" rel="noopener noreferrer">                                    
                                <div className="secretdoor_textBoxCenter" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Open video in a new window</div>
                            </a>
                        </div>       
                    }

                    {!this.state.enableVideoC ? 
                        <div onClick={ () => this.setState({enableVideoC: true}) }>
                            <div className="secretdoor_infoCardPaddedA">
                                <img alt="Secret Door - Portrait Preview" src={portraitVideoImg} className = "secretdoor_twoImageDiv" width = "100%"/>
                                <div className="secretdoor_textBoxCenter">90 Minute Gameplay Video (portrait)</div>
                            </div>                                   
                        </div>
                        :  
                        <div className="secretdoor_infoCardPaddedA">  
                            <div className="sixteenNineAspect">                               
                                <iframe 
                                    className = "movie"
                                    title="YoutubeVideo"
                                    id="0912"                                
                                    src="https://www.youtube.com/embed/fX8A-lUupiU" 
                                    frameBorder="0" 

                                    allow="autoplay; encrypted-media;" 
                                    allowFullScreen
                                    >
                                </iframe> 
                            </div>            
                            <a href="https://www.youtube.com/watch?v=fX8A-lUupiU" target="_blank" rel="noopener noreferrer">                                    
                                <div className="secretdoor_textBoxCenter" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Open video in a new window</div>
                            </a>
                        </div>       
                    }
                </div> */}
                
            

                {/* <br/>  <br/>  <br/> */}
                    <div className="secretdoor_infoCard">
                    <br/>
                        {/* <div className = "secretdoor_textBoxCenter">
                            This game is in Public Beta for iOS:
                        </div>
                        <a href={testFlightBetaURL}> 
                            <div className = "secretdoor_linkText">
                                Install Secret Door for iOS
                            </div>
                        </a>
                        <br/>
                        <br/>
                        <br/>
                        <div className = "secretdoor_textBoxCenter">
                            Builds are also available for Android, Windows, and OSX.                            
                        </div>
                        */}
                        <br/> 

                        <div className = "secretdoor_textBoxTitle secretdoor_textBoxTitleCentered">
                            Secret Door is currently in Public Beta.
                        </div>
                        <br/>

                        <div className = "secretdoor_textBoxCenter">
                            Enter your email address to join the beta and download the game.
                        </div>
                        <br/>
                        <br/>




                        {/* WIP EMAIL FORM */}
                        {/* <div className = "secretdoor_textBoxCenter">                            
                           Enter your email to join the Open beta and try Secret Door.                        
                        </div> */}

<br/>

                        <div>
                            <div className="secretdoor_textBoxCenter">iOS Testers:</div>
                            <form action="https://formsubmit.co/21e9d098d60348cfaae96ac150642585" method="POST">                           
                            <input  type="hidden" id="SignupType" name="SignupType" value="Secret Door Beta"></input>
                            <input  type="hidden" id="Platform" name="Platform" value="iOS"></input>
                            <input type="email" className="inputBox" name="email" placeholder="Enter your Email Address" required></input>
                                <button type="submit" className="button">Test iOS</button>
                            </form>  
                        </div>
                        <br/>                        <br/>


                        <div>
                            <div className="secretdoor_textBoxCenter">Android Testers:</div>
                            <form action="https://formsubmit.co/21e9d098d60348cfaae96ac150642585" method="POST">                           
                            <input  type="hidden" id="SignupType" name="SignupType" value="Secret Door Beta"></input>
                            <input  type="hidden" id="Platform" name="Platform" value="Android"></input>
                            <input type="email" className="inputBox" name="email" placeholder="Enter your Email Address" required></input>
                                <button type="submit" className="button buttonGreen">Test Android</button>
                            </form>  
                        </div>






                        
                        {/* <div className = "secretdoor_textBoxCenter">                            
                            To request a build please email me at:                        
                        </div>
                        <a href="mailto:finlaypatersongames@gmail.com"> 
                            <div className = "secretdoor_linkText">
                                finlaypatersongames@gmail.com
                            </div>
                        </a> */}






                        <br/>
                        <br/>
                    </div>
                {/* <br/><br/><br/>  <br/>  <br/> */}
                <br/><br/>

            </div>
            <div className="pageBreakGraphic"></div>
            <div className="pageBreakGraphicB"></div>
            <div className="pageBreakGraphicC"></div>

                </div>
                    <div className = "secretdoor_footer" >
                    <br/><br/><br/>
                    <div className = "secretdoor_footerTextBox">
                        Coming soon to:
                            <br/>
                        </div>
                    
                    {/* <div className = "secretDoor_downloadLinks">
                        <img alt="iOS App Store Link" src={iosLinkImg} className = "secretdoor_linkImgWide"/> 
                    </div> */}

                    {/* all the logos... */}
                    <div className = "secretDoor_downloadLinks">
                        <img alt="iOS App Store Link" src={iosLinkImg} className = "secretdoor_linkImgWide"/> 
                        <img alt="Android App Store Link" src={androidImg} className = "secretdoor_linkImgWide"/>
                    </div>
                    <br/>
                    <div className = "secretDoor_downloadLinks">
                    {/* <img alt="Steam Store Link" src={steamImg} className = "secretdoor_linkImg"/>  */}
                    {/* <img alt="Itch Store Link" src={steamImg} className = "secretdoor_linkImg"/>  */}
                        {/* <img alt="Nintendo Switch Store Link" src={switchImg} className = "secretdoor_linkImg"/> */}
                        {/* <img alt="Playstation Store Link" src={playstationImg} className = "secretdoor_linkImg"/> */}
                        {/* <img alt="Xbox Store Link Store Link" src={xboxImg} className = "secretdoor_linkImg"/> */}
                    </div>


                    <div className = "secretdoor_footerTextBoxName">
                   
                        <div className = "whiteText">
                            {footerCopyright}
                        </div>


                            {/* <a href="https://www.finlaypaterson.ca/games"> More Games... </a> */}
                        </div>
                    </div>
            </div>
            )}
        }
export default GameWebpage


