import React from "react"




const headerText = "Finlay Paterson - Demo Reel 2024"




class DemoReel extends React.Component {
    constructor(){
        super()
        this.state = {
            enableVideoA: false,           
            // enableVideoB: false,           
            // enableVideoC: false,           
        }
      }

    render() {
        return(
            <div className="demoReelGroup">
                <div className= "demoreelBG">
                <br/> 
                <br/> 
                <br/> 
                <div className = "blackText">
                    {headerText}

                </div>
                <br/> 
                <br/>
                <br/> 
                <div className="sixteenNineAspect">                               
                        <iframe 
                            className = "demoReel"
                            title="YoutubeVideo"
                            id="0912"                                
                            src="https://www.youtube.com/embed/irTuszjL38Q?playlist=irTuszjL38Q&hd=1&rel=0&autoplay=1&loop=1&playsinline=1" 
                            allow="autoplay; encrypted-media;" 
                            allowFullScreen
                            >
                        </iframe> 
                    </div>   
                      
                    <br/> 


                </div>
            </div>
            )}
        }
export default DemoReel


